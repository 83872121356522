export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";



export const LOGIN_REQUEST_SOCIAL = "LOGIN_REQUEST_SOCIAL";
export const LOGIN_SUCCESS_SOCIAL = "LOGIN_SUCCESS_SOCIAL";
export const LOGIN_FAILURE_SOCIAL = "LOGIN_FAILURE_SOCIAL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const USER_DATA = "USER_DATA";
