export const SAVE_PAYLOAD_SUCCESS = "SAVE_PAYLOAD_SUCCESS";
export const SAVE_PAYLOAD_REQUEST = "SAVE_PAYLOAD_REQUEST";

export const ITENERARY_SEARCH_REQUEST = "ITENERARY_SEARCH_REQUEST";
export const ITENERARY_SEARCH_SUCCESS = "ITENERARY_SEARCH_SUCCESS";


export const HOTEL_ITENERARY_REQUEST = "HOTEL_ITENERARY_REQUEST";
export const HOTEL_ITENERARY_SUCCESS = "HOTEL_ITENERARY_SUCCESS";


export const SELECTED_HOTELROOM_SUCCESS = "SELECTED_HOTELROOM_SUCCESS";
export const SELECTED_HOTELROOM_REQUEST = "SELECTED_HOTELROOM_REQUEST";

export const FLIGHT_FROM_REQUEST = "FLIGHT_FROM_REQUEST";
export const FLIGHT_FROM_SUCCESS = "FLIGHT_FROM_SUCCESS";

export const FLIGHT_TO_REQUEST = "FLIGHT_TO_REQUEST";
export const FLIGHT_TO_SUCCESS = "FLIGHT_TO_SUCCESS";

export const FLIGHT_ONEWAY_REQUEST = "FLIGHT_ONEWAY_REQUEST";
export const FLIGHT_ONEWAY_SUCCESS = "FLIGHT_ONEWAY_SUCCESS";

export const SELECTED_FLIGHT_REQUEST = "SELECTED_FLIGHT_REQUEST";
export const SELECTED_FLIGHT_SUCCESS = "SELECTED_FLIGHT_SUCCESS";


export const SELECTED_HOTEL_ROOM_REQUEST = "SELECTED_HOTEL_ROOM_REQUEST";
export const SELECTED_HOTEL_ROOM_SUCCESS = "SELECTED_HOTEL_ROOM_SUCCESS";


export const SELECTED_ACTIVITY_REQUEST = "SELECTED_ACTIVITY_REQUEST";
export const SELECTED_ACTIVITY_SUCCESS = "SELECTED_ACTIVITY_SUCCESS";


export const CLEAR_FLIGHT_REDUCER = "CLEAR_FLIGHT_REDUCER";
export const CLEAR_ITENARYONEWAY_REDUCER = "CLEAR_ITENARYONEWAY_REDUCER";

export const CLEAR_ITENARY_DATA = "CLEAR_ITENARY_DATA";