
import Download from "../../pages/home/Download";
// import Bussearch from "./Bussearch";
// import "./bus.css";
import Advertise from "../home/Advertise";
// import FLightOffer from "../flight/FLightOffer";
// import hotelBanner from "../../images/hotelBanner.jpg"
import busBanner from "../../images/busNew.jpg"
import WhyChooseUs from "../../components/WhyChooseUs";
import { Helmet } from "react-helmet-async";
import Partners from "../home/Partners";
import NewHolidayCategory from "../NewPackagePages/holidayCategory/NewHolidayCategory";
import NewHolidayTrending from "../NewPackagePages/holidayTrending/NewHolidayTrending";
import HolidayTopCountries from "../NewPackagePages/holidayCountries/HolidayTopCountries";
import Blog from "../home/Blog";
import Img from "../../LazyLoading/Img";
import BusForm from "./BusForm";


const Bus = () => {
  return (
    <div>
      <div className="hotel_banner" style={{ background: "white" }}>
        <Helmet>
          <title>The Skytrails - Hotel Booking, Flight Booking, Bus Booking</title>
          <link rel="canonical" href="/bus" />
          <meta name="description" content="bus" />
          <meta name="keywords" content="online bus booking,cheap bus ticket,compare bus fare,best bus deal,last minute bus booking,luxury bus travel,comfortable bus journeys,overnight bus trips,scenic bus routes,student bus passes,sleeper bus with AC,bus with Wi-Fi and charging points,pet-friendly bus travel,luggage allowance on buses " />
        </Helmet>

        <div className='heroBannerFlight'>
          <div className="backdrop-img">
            <Img src={busBanner} />
          </div>
          <div className="opacity-layer"></div>
          <div className="heroBannerContent container">
            <span className="headingTitle">Make booking easy and convenient.</span>
            {/* <GrmHotelForm /> */}
            <BusForm />
            {/* <Bussearch /> */}
          </div>
        </div>

        <div className="mt-3">
          <NewHolidayCategory />
        </div>
        <div>
          <NewHolidayTrending />
        </div>

        <div>
          <Advertise />
        </div>

        <div>
          <HolidayTopCountries />
        </div>

        <div>
          <WhyChooseUs />
        </div>
        <div>
          <Download />
        </div>
        <div>
          <Partners />
        </div>

        <div>
          <Blog />
        </div>
      </div>

    </div>
  );
};
export default Bus;
