import React from 'react'
// import hotelloading from '../../../images/loading/hotelLoading.gif'
import './hotelLoading.css';
const HotelLoading = () => {
    return (
        <div className='hotelLoading'>
            {/* <img src={hotelloading} /> */}
        </div>
    )
}

export default HotelLoading