import React from 'react'
import Oneway from "../../../src/components/Oneway"

const Flighterror = (props) => {
  return (
    <>
      <div className='mainimg'>
        {/* <Navbar /> */}
        {/* <BigNavbar /> */}
        {/* <Mainheader /> */}
        <Oneway />

      </div></>
  )
}

export default Flighterror
