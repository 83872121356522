import React from 'react'
import './Bookingloader.css';

function Bookingloader() {
  return (
    <>
        <div className='flightLoading'>

  
<div class="loader12"></div>
<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
<h1 style={{textAlign:"center"}}>Hold on! <br/>Your Booking is in progress don't back OR refresh</h1>
</div>

</div>
    </>
  )
}

export default Bookingloader