

export const FETCH_TNPL_REQUEST = 'FETCH_TNPL_REQUEST';
export const FETCH_TNPL_SUCCESS = 'FETCH_TNPL_SUCCESS';
export const FETCH_TNPL_FAILURE = 'FETCH_TNPL_FAILURE';

export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';

// submit opt data 

export const SUBMIT_OTP_REQUEST = 'SUBMIT_OTP_REQUEST';
export const SUBMIT_OTP_SUCCESS = 'SUBMIT_OTP_SUCCESS';


export const TNPL_PLANGENERATOR_REQUEST = 'TNPL_PLANGENERATOR_REQUEST';
export const TNPL_PLANGENERATOR_SUCCESS = 'TNPL_PLANGENERATOR_SUCCESS';


export const CLEAR_TNPL_DATA = "CLEAR_TNPL_DATA";