export const ONE_WAY_REQUEST = "ONE_WAY_REQUEST";
export const ONE_WAY_SUCCESS = "ONE_WAY_SUCCESS";

export const CLEAR_ONEWAY_REDUCER = "CLEAR_ONEWAY_REDUCER";
export const ONE_WAY_FAIL = "ONE_WAY_FAIL";
export const ONE_WAY_REQUEST_COMBINED = "ONE_WAY_REQUEST_COMBINED";
export const ONE_WAY_FAIL_COMBINED = "ONE_WAY_FAIL_COMBINED";
export const ONE_WAY_SUCCESS_COMBINED = "ONE_WAY_SUCCESS_COMBINED";


